<template>
  <div>
    <v-col class="mx-1 my-2  ">
      <v-card>
        <v-row class="px-2 py-2">
          <v-spacer />

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  v-model="startDate"
                  label="Tarih"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                no-title
                scrollable
              >
                <v-spacer />
                <Button
                  :text="true"
                  color="primary"
                  @click="menu = false"
                >
                  Kapat
                </Button>
                <Button
                  :text="true"
                  color="primary"
                  @click="$refs.menu.save(startDate)"
                >
                  Seç
                </Button>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="2"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <Select
              v-model="santralModel"
              :items="santralList"
              label="Santral"
              :hide-details="true"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            sm="5"
            md="3"
            lg="3"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <Select
              v-model="inverter"
              :items="inverterList[santralModel]"
              label="İnverter"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="2"
            md="2"
            lg="2"
            xl="1"
            class="  py-0 my-0  mb-2"
          >
            <Button
              color="primary"
              @click="getDailyMinuteEnergy"
            >
              Filtrele
            </Button>
          </v-col>
        </v-row>

        <v-col
          cols="12"
          class="text-center"
        >
          <span
            v-if="santralModel !== null"
            class="pr-3"
          > <h3>
            {{ santralList[santralModel] }} santrali - {{ selectedInverter.text }} inverter
          </h3>
          </span>
        </v-col>
      </v-card>
    </v-col>

    <v-row>
      <v-col
        v-for="measure in measures"
        ref="renderComponent"
        :key="measure.value"
        cols="12"
        sm="12"
        md="12"
        lg="6"
        xl="6"
        class="my-1 py-1   "
      >
        <v-card>
          <echart
            :id="measure.value + '-inverter-value'"
            class-name="widget-content"
            height="350px"
            width="100%"
            :chart-data="chartData[measure.value]"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import * as LineChartOption from '@/api/Inverter/ChartOption';
import moment from 'moment';
import Echart from '@/views/components/Echart/Chart.vue';
import i18n from '@/locale';
import helper from '@/api/helper';

export default {
  name: 'Dashboard',
  components: {
    Echart,
  },
  data: () => ({
    inverter: null,
    startDate: moment().format('YYYY-MM-DD'),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format('YYYY'),
    menu: false,
    modal: false,
    menu2: false,
    chartData: {},
    measures: [],
    measureList: [],
    inverterList: [],
    santralModel: null,
    santralInfo: [],
    inverterListData: {},
    loading: false,
    santralData: {},
    selectedInverter: {},
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    santralList() {
      const { santralRawData } = this.$store.getters;

      if (santralRawData === undefined) return;
      if (santralRawData.length === 0) return;

      const santralList = [];

      santralRawData.forEach((item) => {
        santralList.push({
          text: item.santral,
          value: item.santralCode,
        });
      });
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.santralModel = santralList[0].value;
      // eslint-disable-next-line consistent-return
      return santralList;
    },
  },
  watch: {
    inverter() {
      this.chartData = {};

      if (this.santralModel != null) {
        this.measureList = this.inverterListData[this.santralModel][this.inverter];
      }

      if (this.loading === false) {
        this.getDailyMinuteEnergy(); // TODO burası düzeltilecek
      }
    },
  },
  created() {},
  mounted() {
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    let k = 0;
    function myTimer1() {
      if (k > 30) {
        clearInterval(myVar1);
      }
      k += 1;
      const { santralInverterData, santralRawData } = self.$store.getters;

      if (Object.keys(santralInverterData).length > 0 && Object.keys(self.santralList).length > 0) {
        clearInterval(myVar1);

        self.santralData = {};
        self.santralModel = null;
        santralRawData.forEach((item) => {
          self.santralList.push({
            text: item.santral,
            value: item.santralCode,
          });
          self.santralData[item.santralCode] = item;
        });

        self.santralModel = self.santralList[0].value;
        self.setSantralParameters();
      }
    }
  },

  methods: {
    setSantralParameters() {
      const measureList = {};
      const mList = [];
      const inverterList = {};
      const inverterListData = {};
      const formule = ['avg', 'sum'];
      const { santralInverterData } = this.$store.getters;
      this.loading = true;
      this.inverterCount = 0;
      Object.keys(santralInverterData).forEach((santral) => {
        inverterListData[santral] = {};
        inverterList[santral] = [];

        Object.keys(santralInverterData[santral]).forEach((i) => {
          inverterList[santral].push({
            text: santralInverterData[santral][i].INVERTERNAME,
            value: santralInverterData[santral][i].INVERTER,
          });
          if (this.inverter == null) {
            this.inverter = santralInverterData[santral][i].INVERTER;
          }
          const rawmeasures = santralInverterData[santral][i].general_measure_list;
          const listmeasure = this.setArrayAddKeyToObject(rawmeasures, 'value');

          // eslint-disable-next-line max-len
          inverterListData[santral][santralInverterData[santral][i].INVERTER] = this.setMeasureList(listmeasure);
          Object.keys(rawmeasures).forEach((k) => {
            const measure = rawmeasures[k];
            if (
              mList.indexOf(measure.value) === -1
              && formule.indexOf(measure.formuletype) > -1
            ) {
              mList.push(measure.value);
              measureList[measure.value] = {
                text: i18n.t(measure.text),
                value: measure.value,
                unit: measure.unit,
                type: measure.type,
              };
            }
          });
        });
      });
      this.measures = this.setMeasureList(measureList);
      this.inverterList = inverterList;
      this.inverterListData = inverterListData;
      this.loading = false;
      this.getDailyMinuteEnergy();
    },

    setArrayAddKeyToObject(value, key) {
      const newObject = {};
      Object.keys(value).forEach((i) => {
        newObject[value[i][key]] = value[i];
      });

      return newObject;
    },

    setMeasureList(measures) {
      const measureList = [
        'ACPower',
        'DCPower',

        'DailyPower',
        'Efficiency',
        'TheoreticalPower',
        'PerformanceRatio',
        'TotalEnergy',
        'TempBoard',
        'm1ACPower',
        'm2ACPower',
        'm3ACPower',
        'ACVoltageR',
        'ACVoltageS',
        'ACFrequency',
        'ACCurrentR',
        'ACCurrentS',
        'ACCurrentT',
        'ACPowerReactive',
      ];
      const newMeasures = [];
      measureList.forEach((item) => {
        if (helper.isObjectKeysExist(measures, item)) {
          newMeasures.push(measures[item]);
        }
      });
      return newMeasures;
    },
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate).format('YYYY-MM-DD');
      this.loading = false;
      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          santral: this.santral,
          inverter: this.inverter,
        },
      };

      this.inverterList[this.santralModel].forEach((item) => {
        if (item.value === this.inverter) {
          this.selectedInverter = item;
        }
      });

      const tempData = LineChartOption.default.getSantralComparisonMinuteChart1(
        params,
        this.measures,
      );
      tempData.then((res) => {
        this.chartData = res;
      });
    },
  },
};
</script>
