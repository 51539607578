import { getInverterDataList } from '@/api/Request/inverter';
import request from '../utils';
import chartOptions from '../chart/chart_options';
// eslint-disable-next-line import/extensions
import common from '../component/common.js';
import store from '../../store';

const getInverterLiveData = (formdata, measureList) => new Promise((resolve) => {
  getInverterDataList(formdata).then((_rawData) => {
    const option = {};
    const tempDataType = [];
    let index = 0;
    const sortType = 'datetimestamp';
    // eslint-disable-next-line no-unused-vars
    const res1 = _rawData.data.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });

    measureList.forEach((measure) => {
      const data = [];
      const seriesList = [];

      Object.keys(res1).forEach((key) => {
        if (index === 0) {
          tempDataType.push(res1[key].date);
        }

        if (measure.value === 'Efficiency') {
          if (res1[key][measure.value] < 120) {
            data.push(res1[key][measure.value]);
          }
        } else {
          data.push(res1[key][measure.value]);
        }
      });
      // eslint-disable-next-line no-plusplus
      index++;

      seriesList.push({
        name: measure.text,
        type: 'line',
        stack: 'x',
        data,
      });

      const colors = ['#5470C6', '#91CC75', '#EE6666'];

      option[measure.value] = chartOptions.line_options(tempDataType, measure,
        seriesList, colors);
    });

    resolve(option);
  });
});

const getSantralComparisonMinuteChart1 = (formdata, measureList) => new Promise((resolve) => {
  // eslint-disable-next-line camelcase

  getInverterDataList(formdata).then((res) => {

    const tempTimeData = {};
    res.data.data.forEach((item) => {
      let hh;
      let mm;

      // eslint-disable-next-line no-unused-expressions
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
      // eslint-disable-next-line no-unused-expressions
      item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
      const date = `${hh}:${mm}`;
      // eslint-disable-next-line no-use-before-define
      measureList.forEach((measure) => {
        if (!tempTimeData[measure.value]) {
          tempTimeData[measure.value] = {};
        }

        tempTimeData[measure.value][date] = item;
      });
    });

    let index = 0;
    const stepTime = 10;
    const result = {};

    measureList.forEach((selectMeasure) => {
      const minute = 0;
      const hour = 5;
      const tempData = [];
      const dateList = [];
      const yAxis = [];
      const series = [];

      // eslint-disable-next-line no-plusplus
      for (let i = hour; i <= 20; i++) {
        for (let x = minute; x < 60; x += stepTime) {
          let h;
          let
            m;
          // eslint-disable-next-line no-unused-expressions
          i < 10 ? h = `0${i}` : h = `${i}`;
          // eslint-disable-next-line no-unused-expressions
          x < 10 ? m = `0${x}` : m = `${x}`;
          const time = `${h}:${m}`;
          const obj = {};
          obj.date = time;

          if (dateList.indexOf(time) < 0) {
            dateList.push(time);
          }

          let tempVal = 0;

          if (selectMeasure.value in tempTimeData) {
            if (time in tempTimeData[selectMeasure.value]) {
              tempVal = tempTimeData[selectMeasure.value][time][selectMeasure.value];
            }
          }

          tempData.push(tempVal);
        }
      }

      const obj = {
        name: ` ${selectMeasure.text} ( ${selectMeasure.unit} )`,
        data: tempData,
        type: 'line',
      };

      yAxis.push({
        type: 'value',
        name: selectMeasure.value,
      });

      const yaxis = {
        type: 'value',
        name: `${selectMeasure.text} ( ${selectMeasure.unit} )`,
        show: false,
      };
      if (index > 0) {
        yaxis.show = false;
      }

      yAxis.push(yaxis);
      series.push(obj);
      // eslint-disable-next-line no-plusplus
      index++;
      const colors = ['#5470C6', '#91CC75', '#EE6666'];

      result[selectMeasure.value] = chartOptions.line_options(dateList, selectMeasure,
        series, colors);
    });
    resolve(result);
  });
});

const getSantralComparisonMinuteChart = (formdata, measure, text, chartTypeModel,
  reportTypeModel, santralModel, inverterList, stepTime) => new Promise((resolve) => {
  // eslint-disable-next-line camelcase
  const temp_Data = request.Api(formdata.url, formdata.params, 'post');
  temp_Data.then((_rawData) => {
    const sortType = common.setSortType(reportTypeModel);

    const res = _rawData.data.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });

    const tempTimeData = {};
    res.forEach((item) => {
      let hh;
      let mm;
      const {
        INVERTER,
      } = item;
      // eslint-disable-next-line no-unused-expressions
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;
      // eslint-disable-next-line no-unused-expressions
      item.MinuteGrup < 10 ? mm = `0${item.MinuteGrup}` : mm = `${item.MinuteGrup}`;
      const date = `${hh}:${mm}`;

      if (!tempTimeData[INVERTER]) {
        tempTimeData[INVERTER] = {};
      }

      tempTimeData[INVERTER][date] = item;
    });

    const desserts = [];
    const dessert = {};
    const dateList = [];
    const yAxis = [];
    const series = [];
    const result = {};

    const {
      measures,
    } = formdata.params.condiniton;
    const {
      measureList,
    } = store.getters;
    let selectMeasure;

    measureList.INVERTER.forEach((item) => {
      if (item.text === measures) {
        selectMeasure = {
          text: item.text,
          value: item.text,
          unit: item.unit,
        };
      }
    });

    let index = 0;

    inverterList.forEach((str) => {
      const minute = 0;
      const hour = 5;
      const tempData = [];

      // eslint-disable-next-line no-plusplus
      for (let i = hour; i <= 20; i++) {
        for (let x = minute; x < 60; x += stepTime) {
          let h;
          let
            m;
          // eslint-disable-next-line no-unused-expressions
          i < 10 ? h = `0${i}` : h = `${i}`;
          // eslint-disable-next-line no-unused-expressions
          x < 10 ? m = `0${x}` : m = `${x}`;
          const time = `${h}:${m}`;
          const obj = {};
          obj.date = time;

          if (dateList.indexOf(time) < 0) {
            dateList.push(time);
          }

          let tempVal = 0;

          if (str.value in tempTimeData) {
            if (time in tempTimeData[str.value]) {
              const tempValue = tempTimeData[str.value][time];
              tempVal = common.setMeasures(selectMeasure, tempValue);
            }
          }

          tempData.push(tempVal);

          obj[str.value] = tempVal;
          dessert[time] = {
            ...dessert[time],
            ...obj,
          };
        }
      }

      const obj = {
        name: `${str.text} ${selectMeasure.text} ( ${selectMeasure.unit} )`,
        data: tempData,
        type: chartTypeModel,
      };

      yAxis.push({
        type: 'value',
        name: selectMeasure.value,
      });

      const yaxis = {
        type: 'value',
        name: `${selectMeasure.text} ( ${selectMeasure.unit} )`,
        show: false,
      };
      if (index > 0) {
        yaxis.show = false;
      }

      yAxis.push(yaxis);
      series.push(obj);
      // eslint-disable-next-line no-plusplus
      index++;
    });
    Object.keys(dessert).forEach((key) => {
      desserts.push(dessert[key]);
    });

    const options = chartOptions.line_options_multies_1(text, inverterList, dateList,
      series, common.colors(), yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);
  });
});

const getSantralComparisonHourlyChart = (formdata, measure, text, chartTypeModel,
  reportTypeModel, santralModel, inverterList) => new Promise((resolve) => {
  // eslint-disable-next-line camelcase
  const temp_Data = request.Api(formdata.url, formdata.params, 'post');
  temp_Data.then((_rawData) => {
    const sortType = common.setSortType(reportTypeModel);

    const res = _rawData.data.data.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;

      return 0;
    });

    const tempTimeData = {};
    res.forEach((item) => {
      let hh;

      const {
        INVERTER,
      } = item;
      // eslint-disable-next-line no-unused-expressions
      item.Hour < 10 ? hh = `0${item.Hour}` : hh = `${item.Hour}`;

      const date = `${hh}`;

      if (!tempTimeData[INVERTER]) {
        tempTimeData[INVERTER] = {};
      }

      tempTimeData[INVERTER][date] = item;
    });

    const desserts = [];
    const dessert = {};
    const dateList = [];
    const yAxis = [];
    const series = [];
    const result = {};

    const {
      measures,
    } = formdata.params.condiniton;

    const {
      measureList,
    } = store.getters;
    let selectMeasure;

    measureList.INVERTER.forEach((item) => {
      if (item.text === measures) {
        selectMeasure = {
          text: item.text,
          value: item.text,
          unit: item.unit,
        };
      }
    });

    let index = 0;

    inverterList.forEach((str) => {
      let total = 0;
      const hour = 5;
      const tempData = [];

      // eslint-disable-next-line no-plusplus
      for (let i = hour; i <= 20; i++) {
        let h;

        // eslint-disable-next-line no-unused-expressions
        i < 10 ? h = `0${i}` : h = `${i}`;

        const time = h;
        const obj = {};
        obj.date = time;

        if (dateList.indexOf(time) < 0) {
          dateList.push(time);
        }

        let tempVal = 0;

        if (tempTimeData[str.value] !== undefined) {
          const tempValue = tempTimeData[str.value][time];
          if (tempValue !== undefined) {
            tempVal = common.setMeasures(selectMeasure, tempValue, total);

            // eslint-disable-next-line no-restricted-globals
            if (!isNaN(tempValue[selectMeasure.value])) {
              total = tempValue[selectMeasure.value];
            }
          }
        }

        tempData.push(tempVal);

        obj[str.value] = tempVal;
        dessert[time] = {
          ...dessert[time],
          ...obj,
        };
      }

      const obj = {
        name: `${str.text} ${selectMeasure.text} ( ${selectMeasure.unit} )`,
        data: tempData,
        type: chartTypeModel,
      };

      yAxis.push({
        type: 'value',
        name: selectMeasure.value,
      });

      const yaxis = {
        type: 'value',
        name: `${selectMeasure.text} ( ${selectMeasure.unit} )`,
      };
      if (index > 0) {
        yaxis.show = false;
      }

      yAxis.push(yaxis);

      series.push(obj);
      // eslint-disable-next-line no-plusplus
      index++;
    });
    Object.keys(dessert).forEach((key) => {
      desserts.push(dessert[key]);
    });

    const options = chartOptions.line_options_multies_1(text, inverterList, dateList,
      series, common.colors(), yAxis);

    result.options = options;
    result.desserts = desserts;

    resolve(result);
  });
});

export default {
  getInverterLiveData,
  getSantralComparisonMinuteChart1,
  getSantralComparisonMinuteChart,
  getSantralComparisonHourlyChart,
};
